import React from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { values as getValues } from 'ramda'

import { API_BASE_URL } from 'src/config'
import { userFieldNames as fieldNames } from 'components/user/constants'
import {
  checkServerValidationErrors,
  preparePhoneNumber,
  prepareDateString
} from 'utils'
import UserSignUp from './UserSignUp'

function UserSignUpContainer({
  onClickLogIn,
  onSubmitSuccess,
}) {
  const formik = useFormik({
    initialValues: {
      [fieldNames.LAST_NAME]: '',
      [fieldNames.FIRST_NAME]: '',
      [fieldNames.PATRONYMIC]: '',
      [fieldNames.GENDER]: '',
      [fieldNames.BIRTH_DAY]: '',
      [fieldNames.PHONE]: '',
      [fieldNames.EMAIL]: '',
      // [fieldNames.AGREE]: false,
      [fieldNames.SUBSCRIBE]: true
    },

    validationSchema: Yup.object().shape({
      [fieldNames.LAST_NAME]: Yup.string().required('Обязательное поле'),
      [fieldNames.FIRST_NAME]: Yup.string().required('Обязательное поле'),
      [fieldNames.PATRONYMIC]: Yup.string(),
      [fieldNames.PHONE]: Yup.string().required('Обязательное поле'),
      [fieldNames.GENDER]: Yup.string().oneOf(['1', '2']).required('Обязательное поле'),
      [fieldNames.BIRTH_DAY]:
        Yup
        .string()
        .matches(/\d\d\/\d\d\/\d\d\d\d/, 'Введите правильный формат даты')
        .required('Обязательное поле'),
      // [fieldNames.AGREE]: Yup.boolean().oneOf([true], 'Обязательное поле'),
      [fieldNames.EMAIL]: Yup.string().email('Некорректный email').required('Обязательное поле'),
    }),

    onSubmit: (values, { setFieldError }) => {
      const preparedValues = {
        ...values,
        // [fieldNames.PHONE]: preparePhoneNumber(values[fieldNames.PHONE]),
        [fieldNames.BIRTH_DAY]: prepareDateString(values[fieldNames.BIRTH_DAY])
      }

      const submit = async () => {
        try {
          await axios({
            method: 'post',
            url: `${API_BASE_URL}/user/`,
            data: {...preparedValues},
            headers: {
              'Accept': 'application/json'
            }
          })

          onSubmitSuccess(preparedValues)
        } catch(err) {
          checkServerValidationErrors({
            error: err,
            fieldNames: getValues(fieldNames),
            onMatch: (name, validationError) => {
              setFieldError(name, validationError)
            }
          })
        }
      }
      submit()
    }
  })

  return (
    <UserSignUp
      values={formik.values}
      errors={formik.errors}
      touched={formik.touched}
      handleSubmit={formik.handleSubmit}
      handleChange={formik.handleChange}
      setFieldValue={formik.setFieldValue}
      onClickLogIn={onClickLogIn}
    />
  )
}

export default UserSignUpContainer
