import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import UserData from './UserData'
import { updateUserData } from 'components/user/actions'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { userFieldNames } from 'components/user/constants'
import { omit, isEmpty } from 'ramda'
import * as Yup from 'yup'

const mapStateToProps = state => ({
  userData: state.user.data
})

export default connect(
  mapStateToProps
)(UserDataContainer)

UserDataContainer.propTypes = {
  userData: PropTypes.object.isRequired
}

function UserDataContainer({
  userData
}) {
  const dispatch = useDispatch()
  const [isReady, setIsReady] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      [userFieldNames.LAST_NAME]: userData[userFieldNames.LAST_NAME],
      [userFieldNames.FIRST_NAME]: userData[userFieldNames.FIRST_NAME],
      [userFieldNames.PATRONYMIC]: userData[userFieldNames.PATRONYMIC],
      // [userFieldNames.PHONE]: (userData[userFieldNames.PHONE] && userData[userFieldNames.PHONE].substr(1)) || '',
      [userFieldNames.PHONE]: userData[userFieldNames.PHONE] || '',
      [userFieldNames.ADDRESS]: userData[userFieldNames.ADDRESS]
    },

    validationSchema: Yup.object().shape({
      [userFieldNames.LAST_NAME]: Yup.string().required('Обязательное поле'),
      [userFieldNames.FIRST_NAME]: Yup.string().required('Обязательное поле'),
      [userFieldNames.PATRONYMIC]: Yup.string().required('Обязательное поле'),
    }),

    onSubmit: (values) => {
      const preparedValues = omit([
        userFieldNames.PHONE,
        userFieldNames.ADDRESS
      ], values)

      dispatch(updateUserData(preparedValues)).then(res => {
        console.log(res)
      }).catch(err => {
        console.error(err)
      })
    }
  })

  useEffect(() => {
    if (!isEmpty(userData)) {
      setIsReady(true)
    }
  }, [userData])

  if (!isReady) {
    return null
  }

  return <UserData
    values={formik.values}
    errors={formik.errors}
    touched={formik.touched}
    handleSubmit={formik.handleSubmit}
    handleChange={formik.handleChange}
    setFieldValue={formik.setFieldValue}
  />
}
