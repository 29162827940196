import { pathOr, isNil, not } from 'ramda'

export default function checkServerError({
  error,
  onMatch,
}) {
  const serverError = pathOr(null, ['response', 'data', 'non_field_errors'], error)

  if (not(isNil(serverError))) {
    onMatch(serverError[0])
  }
}
