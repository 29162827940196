import React from 'react'
import UserPasswordRecoveryView from './View'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { userFieldNames as fieldNames } from 'components/user/constants'
import { preparePhoneNumber } from 'utils'
import axios from 'axios'
import { API_BASE_URL } from 'src/config'

export default function UserPasswordRecoveryContainer({
  onSubmitSuccess
}) {
  const formik = useFormik({
    initialValues: {
      [fieldNames.PHONE]: ''
    },

    validationSchema: Yup.object().shape({
      [fieldNames.PHONE]: Yup.string().required('Обязательное поле'),
    }),

    onSubmit: values => {
      const preparedValues = {...values,
        [fieldNames.PHONE]: preparePhoneNumber(values[fieldNames.PHONE])
      }

      axios.put(`${API_BASE_URL}/user/restore/`, {
        ...preparedValues
      }).then(res => {
        if (onSubmitSuccess) {
          onSubmitSuccess(preparedValues[fieldNames.PHONE])
        }
      }).catch(err => {
        console.error(err)
      })
    }
  })

  return <UserPasswordRecoveryView
    values={formik.values}
    errors={formik.errors}
    touched={formik.touched}
    handleChange={formik.handleChange}
    handleSubmit={formik.handleSubmit}
    setFieldValue={formik.setFieldValue}
  />
}
