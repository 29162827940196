import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
// import { ValidationError } from 'ui/common'
import css from './style.module.scss'

export default Textarea

Textarea.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string
}

// console.log(css)

function Textarea({
  id,
  name,
  value = '',
  label,
  placeholder,
  onChange,
  error = '',
  tabIndex = undefined,
}) {
  return (
    <div className={cn('input-text', css.textarea, error && css.__error)}>
      <p className={'input-text__placeholder label-2 dark-grey'}>
        {label}
      </p>
      <div className={css.wrap}>
        <textarea
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          className={cn(value.length && css.NotEmpty)}
          tabIndex={tabIndex}
        />
        <label htmlFor={id}>
          <span>
            {placeholder}
          </span>
        </label>
      </div>

      {error &&
        <p className='validation__error caption red'>{error}</p>
      }
    </div>
  )
}
