import React from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'
// import { ValidationError } from 'ui/common'
import cn from 'classnames'
import css from './style.module.scss'

export default Select

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })),
  onChange: PropTypes.func,
  isSearchable: PropTypes.bool,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string
}

function Select ({
  options,
  onChange,
  isSearchable = false,
  error = '',
  placeholder = '',
  className = '',
  label = '',
  tabIndex = undefined,
}) {
  return (
    <div className={cn(css.selectWrap, className)}>
      <p className={'input-text__placeholder label-2 dark-grey'}>
        {label}
      </p>
      <ReactSelect
        className="select-container"
        classNamePrefix="select"
        options={options}
        isSearchable={isSearchable}
        placeholder={placeholder}
        onChange={onChange}
        label={label}
        tabIndex={tabIndex}
      />
      {error &&
        <p className='validation__error caption red'>{error}</p>
      }
    </div>
  )
}
