import { actionTypes } from './actions'

export const initialState = {
  has_order: false
}

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.ORDER_IS_AVAILABLE:
      return {
        ...state,
        ...payload
      }
    
    default: 
      return state
  }
}