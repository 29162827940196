import React from 'react'
import { useFormik } from 'formik'
import UserPasswordChangeView from './View'
import axios from 'axios'
import { userFieldNames as fieldNames } from 'components/user/constants'
import { API_BASE_URL } from 'src/config'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { checkServerValidationErrors } from 'utils'
import { values as getValues, omit } from 'ramda'
import * as Yup from 'yup'

const mapStateToProps = state => ({
  authToken: state.user.authToken
})

export default connect(mapStateToProps)(UserPasswordChangeContainer)

UserPasswordChangeContainer.propTypes = {
  authToken: PropTypes.any.isRequired
}

function UserPasswordChangeContainer({
  authToken,
  onSubmitSuccess
}) {
  const formik = useFormik({
    initialValues: {
      [fieldNames.OLD_PASSWORD]: '',
      [fieldNames.NEW_PASSWORD]: '',
      [fieldNames.CONFIRM_PASSWORD]: ''
    },

    validationSchema: Yup.object().shape({
      [fieldNames.OLD_PASSWORD]: Yup.string().required('Обязательное поле'),
      [fieldNames.NEW_PASSWORD]: Yup.string().required('Обязательное поле'),
      [fieldNames.CONFIRM_PASSWORD]: Yup.string()
        .oneOf([Yup.ref(fieldNames.NEW_PASSWORD)], 'Пароли не совпадают')
        .required('Обязательное поле')
    }),

    onSubmit: (values, { setFieldError }) => {
      const preparedValues = omit(fieldNames.CONFIRM_PASSWORD, values)

      axios.put(`${API_BASE_URL}/user/password/`, preparedValues, {
        headers: {
          'Authorization': `Token ${authToken}`
        }
      }).then(res => {
        if (onSubmitSuccess) {
          onSubmitSuccess()
        }
      }).catch(err => {
        checkServerValidationErrors({
          error: err,
          fieldNames: getValues(fieldNames),
          onMatch: (name, validationError) =>
            setFieldError(name, validationError)
        })
      })
    }
  })

  return <UserPasswordChangeView
    handleSubmit={formik.handleSubmit}
    handleChange={formik.handleChange}
    values={formik.values}
    errors={formik.errors}
    touched={formik.touched}
  />
}
