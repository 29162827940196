import { actionTypes } from './actions'

export const initialState = {
  authToken: null,
  data: {},
}

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.USER_LOGIN:
      return {
        ...state,
        authToken: payload.authToken,
      }
    
    case actionTypes.USER_VALIDATED_SMS:
      return {
        ...state,
        authToken: payload.authToken,
      }
    
    case actionTypes.USER_SET_DATA:
      return {
        ...state,
        data: payload.userData,
      }

    case actionTypes.USER_LOGOUT:
      return initialState
    
    default:
      return state
  }
}