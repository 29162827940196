import React from 'react'
import css from './style.module.scss'
import { InputText, Button } from 'components/ui'
import { userFieldNames } from 'components/user/constants'
import cn from 'classnames'

export default function({
  values,
  errors,
  handleChange,
  handleSubmit,
  serverError,
}) {
  return (
    <div className={cn(css.smsConfirm, 'form-layout')}>
      <form onSubmit={handleSubmit} autoComplete='off'>
        <div className={cn('form-field', css.password)}>
          <InputText
            type='password'
            placeholder="Пароль"
            onChange={handleChange}
            value={values[userFieldNames.NEW_PASSWORD]}
            name={userFieldNames.NEW_PASSWORD}
            error={errors[userFieldNames.NEW_PASSWORD] || ''}
          />
        </div>
        <div className={cn('form-field', css.password)}>
          <InputText
            type='password'
            placeholder="Повторите пароль"
            onChange={handleChange}
            value={values[userFieldNames.PASSWORD_CONFIRM]}
            name={userFieldNames.PASSWORD_CONFIRM}
            error={errors[userFieldNames.PASSWORD_CONFIRM] || ''}
          />
        </div>
        {serverError &&
          <p className='validation__error caption red'>{serverError}</p>
        }
        <div className="form-button">
          <Button
            text="Сохранить"
            type="submit"
          />
        </div>
      </form>
    </div>
  )
}
