import React from 'react'
import css from './style.module.scss'
import { InputText, Button } from 'components/ui'
import { userFieldNames } from 'components/user/constants'
import cn from 'classnames'

export default function({
  values,
  handleChange,
  handleSubmit,
  errors,
  serverError,
  timeLeft = '2:00',
  onClickResend,
  phone,
}) {
  return (
    <div className={cn(css.smsConfirm, 'form-layout')}>
      <form onSubmit={handleSubmit} autoComplete='off'>

        <p className="body-text">
          Мы отправили новый пароль от вашего аккаунта на указанный номер телефона.
        </p>

        <div className={cn('form-field', css.password)}>
          <InputText
            placeholder="Пароль"
            onChange={handleChange}
            value={values[userFieldNames.PASSWORD]}
            name={userFieldNames.PASSWORD}
            error={errors[userFieldNames.PASSWORD] || ''}
          />
        </div>

        {timeLeft &&
          <div className={cn('caption grey', css.newPass)}>
            {`Получить пароль повторно можно через ${timeLeft}`}
          </div>
        }

        {!timeLeft &&
          <div
            className={cn("label-2 primary-color", css.newPass)}
            onClick={onClickResend}
            role='button'
            tabIndex='0'
            onKeyDown={() => {}}
          >
            Получить пароль повторно
          </div>
        }

        {serverError &&
          <p className='validation__error caption red'>{serverError}</p>
        }

        <div className="form-button">
          <Button
            text="Восстановить пароль"
            type="submit"
          />
        </div>

      </form>

    </div>
  )
}
