import React, { useState, useEffect } from 'react'
import UserPasswordConfirmView from './View'
import { useFormik } from 'formik'
import { userFieldNames } from 'components/user/constants'
import { useDispatch } from 'react-redux'
import { login } from 'components/user/actions'
import { /*preparePhoneNumber, */checkServerValidationErrors, checkServerError } from 'utils'
import axios from 'axios'
import { API_BASE_URL } from 'src/config'
import * as Yup from 'yup'

export default function UserPasswordConfirm({ phone, onSuccessLogin }) {
  const dispatch = useDispatch()
  const [serverError, setServerError] = useState('')
  const [seconds, setSeconds] = useState(120);

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      setSeconds(seconds => seconds - 1);
    }, 1000);

    if (seconds < 0) {
      clearInterval(interval)
    }

    return () => clearInterval(interval);
  }, [seconds]);

  function getTimeLeft() {
    if (seconds < 0) {
      return null
    }
    const m = Math.floor(seconds / 60)
    const s = seconds % 60

    return `
      ${m}:${s < 10 ? `0${s}` : s}
    `
  }

  function onClickResendHandler() {
    axios.put(`${API_BASE_URL}/user/restore/`, {
      [userFieldNames.PHONE]: phone
    }).then(() => {
      setSeconds(120)
    })
  }

  const formik = useFormik({
    initialValues: {
      [userFieldNames.PASSWORD]: ''
    },

    validationSchema: Yup.object().shape({
      [userFieldNames.PASSWORD]: Yup.string().required('Обязательное поле')
    }),

    onSubmit: (values, { setFieldError }) => {
      setServerError('')
      
      dispatch(login(
        phone,
        // preparePhoneNumber(values[userFieldNames.PHONE]),
        values[userFieldNames.PASSWORD]
      )).then(() => {
        if (onSuccessLogin) {
          onSuccessLogin()
        }
      }).catch(err => {
        checkServerValidationErrors({
          error: err,
          fieldNames: [userFieldNames.PASSWORD],
          onMatch: (name, error) => {
            setFieldError(name, error)
          }
        })

        checkServerError({
          error: err,
          onMatch: (errorMessage) => {
            setServerError(errorMessage)
          }
        })
      })

      // dispatch(login(
      //   phone,
      //   values[userFieldNames.PASSWORD]
      // ))
      // .then(() => {
      //   if (onSubmitSuccess) {
      //     onSubmitSuccess(values)
      //   }
      // })
      // .catch(err => {
      //   checkServerValidationErrors({
      //     error: err,
      //     fieldNames: [userFieldNames.PASSWORD],
      //     onMatch: (name, error) => {
      //       setFieldError(name, error)
      //     }
      //   })

      //   checkServerError({
      //     error: err,
      //     onMatch: (error) => {
      //       setServerError(error)
      //     }
      //   })
      // })
    }
  }, [phone])

  return <UserPasswordConfirmView
    values={formik.values}
    errors={formik.errors}
    handleChange={formik.handleChange}
    handleSubmit={formik.handleSubmit}
    serverError={serverError}
    timeLeft={getTimeLeft()}
    onClickResend={onClickResendHandler}
  />
}
