import { actionTypes } from './actions'

export const initialState = {
  data: [],
  isLoaded: false
}

export default function (state = initialState, { type, payload }) {
  switch(type) {
  case actionTypes.CITIES_SET_DATA:
    return {
      ...state,
      data: payload.cities,
      isLoaded: true
    }
  default:
    return state
  }

}
