import React from 'react'
import cn from 'classnames'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ru from 'react-phone-input-2/lang/ru.json'

import css from './style.module.scss'

export default function Phone({
  id,
  name = '',
  placeholder = '',
  value = null,
  error,
  setFieldValue,
  disabled = false,
  tabIndex = undefined,
  ...rest
}) {
  return (
    <div className={cn('input-text', error && '--error')}>
      {placeholder &&
        <p className={'input-text__placeholder label-2 dark-grey'}>
          {placeholder}
        </p>
      }

      <div className={'input-text__input-wrap'}>
        <PhoneInput
          onlyCountries={['az', 'am', 'by', 'kz', 'kg', 'md', 'ru', 'tj', 'tm', 'uz', 'ua', 'ge', 'lv', 'lt', 'ee']}
          id={id}
          localization={ru}
          name={name}
          value={value}
          onChange={v => setFieldValue(name, v)}
          disabled={disabled}
          tabIndex={tabIndex}
          containerClass={css.inputPhoneContainer}
          inputClass={css.inputPhoneInput}
          {...rest}
        />
      </div>

      {error &&
        <p className={'input-text__error caption red'}>{error}</p>
      }
    </div>
  )
}