import { pathOr, isNil, not } from 'ramda'

export default function checkServerValidationErrors({
  error,
  fieldNames,
  onMatch,
}) {
  fieldNames.forEach(name => {
    const validationError = pathOr(null, ['response', 'data', name], error)
    if (not(isNil(validationError))) {
      onMatch(name, validationError[0])
    }
  })
}
