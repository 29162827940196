import React from 'react'
import css from './style.module.scss'
import { InputText, Button, Radio, Checkbox, Phone } from 'components/ui'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { userFieldNames as fieldNames } from 'components/user/constants'

UserSignUp.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  onClickLogIn: PropTypes.func.isRequired
}

export default function UserSignUp({
  handleChange,
  handleSubmit,
  setFieldValue,
  values,
  onClickLogIn,
  errors,
  touched
}) {
  return (
    <div className={cn(css.signUp, 'form-layout')}>
      <form onSubmit={handleSubmit}>
        <div className='form-field'>
          <InputText
            placeholder="Фамилия"
            name={fieldNames.LAST_NAME}
            onChange={handleChange}
            value={values[fieldNames.LAST_NAME]}
            error={touched[fieldNames.LAST_NAME] && errors[fieldNames.LAST_NAME]}
          />
        </div>
        <div className='form-field'>
          <InputText
            placeholder="Имя"
            name={fieldNames.FIRST_NAME}
            value={values[fieldNames.FIRST_NAME]}
            onChange={handleChange}
            error={touched[fieldNames.FIRST_NAME] && errors[fieldNames.FIRST_NAME]}
          />
        </div>
        <div className='form-field'>
          <InputText
            placeholder="Отчество (необязательно)"
            name={fieldNames.PATRONYMIC}
            value={values[fieldNames.PATRONYMIC]}
            onChange={handleChange}
            error={touched[fieldNames.PATRONYMIC] && errors[fieldNames.PATRONYMIC]}
          />
        </div>
        <div className='form-field'>
          {/*<InputText
            placeholder="Номер телефона"
            name={fieldNames.PHONE}
            onChange={handleChange}
            value={values[fieldNames.PHONE]}
            type='phone'
            error={touched[fieldNames.PHONE] && errors[fieldNames.PHONE]}
          />*/}
          <Phone
            placeholder="Номер телефона"
            name={fieldNames.PHONE}
            setFieldValue={setFieldValue}
            error={touched[fieldNames.PHONE] && errors[fieldNames.PHONE]}
            country={'ru'}
          />
        </div>
        <div className='form-field'>
          <InputText
            placeholder="E-mail"
            name={fieldNames.EMAIL}
            value={values[fieldNames.EMAIL]}
            onChange={handleChange}
            error={touched[fieldNames.EMAIL] && errors[fieldNames.EMAIL]}
          />
        </div>
        <div className='form-field'>
          <InputText
            placeholder="Дата рождения"
            name={fieldNames.BIRTH_DAY}
            value={values[fieldNames.BIRTH_DAY]}
            onChange={handleChange}
            type="date"
            error={touched[fieldNames.BIRTH_DAY] && errors[fieldNames.BIRTH_DAY]}
          />
        </div>
        <div className='form-field'>
          <p className={cn(css.placeholder, 'label-2 dark-grey')}>Пол</p>

          <div>
            <div className={css.radioButtons}>
              <div className={css.radioButton}>
                <Radio
                  id="sign_up_male_gender"
                  value="1"
                  text="Мужской"
                  name={fieldNames.GENDER}
                  onChange={handleChange}
                />
              </div>
              <div className={css.radioButton}>
                <Radio
                  id="sign_up_female_gender"
                  value="2"
                  onChange={handleChange}
                  name={fieldNames.GENDER}
                  text="Женский"
                />
              </div>
            </div>

            {touched[fieldNames.GENDER] && errors[fieldNames.GENDER] &&
              <p className='validation__error caption red'>{errors[fieldNames.GENDER]}</p>
            }
          </div>

          <div className='form-field'>
            <Checkbox
              id="subscribe"
              name={fieldNames.SUBSCRIBE}
              value={values[fieldNames.SUBSCRIBE]}
              defaultChecked={values[fieldNames.SUBSCRIBE]}
              onChange={handleChange}
              text="Хочу получать новости от shabbat.ru"
            />
          </div>

          {/*<div className='form-field'>
            <Checkbox
              id="agree"
              name={fieldNames.AGREE}
              value={values[fieldNames.AGREE]}
              defaultChecked={values[fieldNames.AGREE]}
              onChange={handleChange}
              text="Регистрируясь на сайте, я даю согласие на<br /><a href='/privacy-policy/' className='primary-color' target='_blank'>обработку персональных данных</a>&nbsp;и принимаю <a href='/terms-of-service/' className='primary-color' target='_blank'>пользовательское соглашение</a>"
            />
          </div>*/}
          {/*touched[fieldNames.AGREE] && errors[fieldNames.AGREE] &&
            <p className='validation__error caption red'>{errors[fieldNames.AGREE]}</p>
          */}

        </div>

        <div className="form-button">
          <Button text="Зарегистрироваться" type="submit" />
        </div>

      </form>

      <div className='text-center'>
        <p className={cn(css.agreement, 'caption grey')}>
          Регистрируясь на сайте, я даю согласие на<br />
          <a href="/privacy-policy/" className='primary-color' target='_blank'>обработку персональных данных</a>&nbsp;
          и принимаю <a href="/terms-of-service/" className='primary-color' target='_blank'>пользовательское соглашение</a>
        </p>

        <p className='caption grey'>Уже есть учетная запись?</p>
        <span
          className="label-2 primary-color pointer"
          onClick={onClickLogIn}
          role='button'
          tabIndex='0'
          onKeyDown={() => {}}
        >
          Войти
        </span>
      </div>

    </div>
  )
}
