import React from 'react'
import cn from 'classnames'
import MaskedInput from 'react-text-mask'

export default function({
  id,
  name = '',
  value = '',
  placeholder = '',
  error,
  disabled = false,
  onChange = () => {},
  type = 'text',
  tabIndex = undefined,
  ...rest
}) {
  if (value === null) {
    value = ''
  }

  let specificInput

  switch (type) {
    case 'phone':
      specificInput = (<MaskedInput
        mask={['+', '7', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
        placeholder="+7 (___) ___-__-__"
        id={id}
        name={name}
        type="text"
        value={value}
        onChange={onChange}
        aria-label="Mute volume"
        disabled={disabled}
        tabIndex={tabIndex}
      />)
    break;

    case 'date':
      specificInput = (<MaskedInput
        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        placeholder="дд/мм/гггг"
        id={id}
        name={name}
        type="text"
        value={value}
        onChange={onChange}
        aria-label="Mute volume"
        tabIndex={tabIndex}
      />)
    break;

    default:
      specificInput = (<input
        id={id}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        aria-label="Mute volume"
        disabled={disabled}
        tabIndex={tabIndex}
        {...rest}
      />)
  }

  return (
    <div className={cn('input-text', error && '--error')}>
      {placeholder &&
        <p className={'input-text__placeholder label-2 dark-grey'}>
          {placeholder}
        </p>
      }

      <div className={'input-text__input-wrap'}>
        {specificInput}
      </div>

      {error &&
        <p className={'input-text__error caption red'}>{error}</p>
      }
    </div>
  )
}
