import React, { useState } from 'react'
import UserSetPasswordView from './View'
import { useFormik } from 'formik'
import { userFieldNames } from 'components/user/constants'
import { useDispatch } from 'react-redux'
import { setPassword } from 'components/user/actions'
import { checkServerValidationErrors, checkServerError } from 'utils'
import * as Yup from 'yup'

export default function UserSetPassword({ onSubmitSuccess }) {
  const dispatch = useDispatch()
  const [serverError, setServerError] = useState('')

  const formik = useFormik({
    initialValues: {
      [userFieldNames.NEW_PASSWORD]: ''
    },

    validationSchema: Yup.object().shape({
      [userFieldNames.NEW_PASSWORD]: Yup.string()
        .required('Обязательное поле')
        .min(8, 'Пароль должен содержать минимум 8 символов')
        .matches(/[a-z]+/, 'Пароль должен содержать строчные буквы (a-z)'),
      [userFieldNames.PASSWORD_CONFIRM]: Yup.mixed()
        .required('Обязательное поле')
        .test('match', 'Пароли не совпадают', function(value) {
          return value === this.parent[userFieldNames.NEW_PASSWORD]
        }),
    }),

    onSubmit: (values, { setFieldError }) => {
      setServerError('')
      
      dispatch(setPassword(
        values[userFieldNames.NEW_PASSWORD]
      ))
      .then(() => {
        if (onSubmitSuccess) {
          onSubmitSuccess()
        }
      })
      .catch(err => {
        checkServerValidationErrors({
          error: err,
          fieldNames: [userFieldNames.NEW_PASSWORD],
          onMatch: (name, error) => {
            setFieldError(name, error)
          }
        })

        checkServerError({
          error: err,
          onMatch: (error) => {
            setServerError(error)
          }
        })
      })
    }
  })

  return <UserSetPasswordView
    values={formik.values}
    errors={formik.errors}
    handleChange={formik.handleChange}
    handleSubmit={formik.handleSubmit}
    serverError={serverError}
  />
}
