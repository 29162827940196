import React from 'react'
import axios from 'axios'
import { API_BASE_URL } from 'src/config'
import { openPopup, closePopup, popupTypes } from 'components/popups/actions'
import {
  UserLogIn,
  UserSignUp,
  UserSmsConfirm,
  UserPasswordConfirm,
  UserSetPassword,
  UserPasswordChange,
  UserPasswordRecovery,
} from 'components/user'
import { userFieldNames } from './constants'

export const actionTypes = {
  USER_LOGIN: 'USER_LOGIN',
  USER_LOGOUT: 'USER_LOGOUT',
  USER_SET_DATA: 'USER_SET_DATA',
  USER_VALIDATED_SMS: 'USER_VALIDATED_SMS',
}

export function login(username, password) {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'post',
        url: `${API_BASE_URL}/token/`,
        data: {
          username: username,
          password: password
        },
        withCredentials: false
      })

      const { token } = res.data

      dispatch({
        type: actionTypes.USER_LOGIN,
        payload: {
          authToken: token
        }
      })

      return Promise.resolve(res)
    }
    catch(err) {
      return Promise.reject(err)
    }
  }
}

export function validateSms(phone, sms) {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'post',
        url: `${API_BASE_URL}/token/sms/`,
        data: {
          phone: phone,
          sms_code: sms,
        },
        withCredentials: false,
      })

      const { token } = res.data

      dispatch({
        type: actionTypes.USER_VALIDATED_SMS,
        payload: {
          authToken: token
        }
      })

      return Promise.resolve(res)
    }
    catch(err) {
      console.error(err.response)

      return Promise.reject(err)
    }
  }
}

export function setPassword(password) {
  return async (dispatch, getState) => {
    try {
      const { authToken } = getState().user

      const res = await axios({
        method: 'put',
        url: `${API_BASE_URL}/user/password/`,
        data: {
          new_password: password,
        },
        headers: {
          'Authorization': `Token ${authToken}`
        },
        withCredentials: false,
      })

      return Promise.resolve(res)
    }
    catch(err) {
      console.error(err.response)
      
      return Promise.reject(err)
    }
  }
}

export function logout() {
  return {
    type: actionTypes.USER_LOGOUT
  }
}

export function fetchUserData() {
  return async (dispatch, getState) => {
    const { authToken } = getState().user

    try {
      const res = await axios.get(`${API_BASE_URL}/user/`, {
        headers: {
          'Authorization': `Token ${authToken}`
        }
      })
      dispatch({
        type: actionTypes.USER_SET_DATA,
        payload: {
          userData: {...res.data}
        }
      })
      return Promise.resolve()
    } catch(err) {
      dispatch(logout())

      return Promise.reject(err)
    }
  }
}

export function updateUserData(values) {
  return async (dispatch, getState) => {
    const { authToken } = getState().user

    try {
      const res = await axios.patch(`${API_BASE_URL}/user/`, values, {
        headers: {
          'Authorization': `Token ${authToken}`
        }
      })
      dispatch({
        type: actionTypes.USER_SET_DATA,
        payload: {
          userData: {...res.data}
        }
      })
      return Promise.resolve()
    } catch(err) {
      return Promise.reject(err)
    }
  }
}

export function openLogInPopup() {
  return dispatch => {
    dispatch(openPopup(popupTypes.POPUP_LAYOUT, {
      title: 'Войти',
      children: <UserLogIn
        onClickSignUp={() => dispatch(openSignUpPopup())}
        onSuccessLogin={() => dispatch(closePopup())}
        onClickForgotPassword={() => dispatch(openPasswordRecoveryPopup())}
      />
    }))
  }
}

export function openSignUpPopup() {
  return dispatch => {
    dispatch(openPopup(popupTypes.POPUP_LAYOUT, {
      title: 'Зарегистрироваться',
      children: <UserSignUp
        onSubmitSuccess={values => {
          dispatch(openSmsConfirmPopup(values[userFieldNames.PHONE]))}
        }
        onClickLogIn={() => dispatch(openLogInPopup())}
      />
    }))
  }
}

export function openSmsConfirmPopup(phone) {
  return dispatch => {
    dispatch(openPopup(popupTypes.POPUP_LAYOUT, {
      title: 'Войти',
      children: <UserSmsConfirm
        phone={phone}
        onSubmitSuccess={values => {
          dispatch(openSetPasswordPopup())
        }}
      />
    }))
  }
}

export function openPasswordConfirmPopup(phone) {
  return dispatch => {
    dispatch(openPopup(popupTypes.POPUP_LAYOUT, {
      title: 'Введите пароль из СМС',
      children: <UserPasswordConfirm
        phone={phone}
        onSuccessLogin={() => dispatch(closePopup())}
      />
    }))
  }
}

export function openSetPasswordPopup() {
  return dispatch => {
    dispatch(openPopup(popupTypes.POPUP_LAYOUT, {
      title: 'Создайте пароль',
      children: <UserSetPassword
        onSubmitSuccess={() => dispatch(closePopup())}
      />
    }))
  }
}

export function openChangePasswordPopup() {
  return dispatch => {
    dispatch(openPopup(popupTypes.POPUP_LAYOUT, {
      title: 'Сменить пароль',
      children: <UserPasswordChange
        onSubmitSuccess={() => {
          dispatch(openPopup(popupTypes.POPUP_ALERT, {
            title: 'Новый пароль успешно установлен',
            buttonText: 'Закрыть',
            onClickButton: () => {
              dispatch(closePopup())
            }
          }))
        }}
      />
    }))
  }
}

export function openPasswordRecoveryPopup() {
  return dispatch => {
    dispatch(openPopup(popupTypes.POPUP_LAYOUT, {
      title: 'Восстановление пароля',
      children: <UserPasswordRecovery
        onSubmitSuccess={(phone) => {
          dispatch(openPasswordConfirmPopup(phone))
        }}
      />
    }))
  }
}