import React from 'react'
import { InputText, Button, Phone } from 'components/ui'
import { userFieldNames as fieldNames } from 'components/user/constants'

const AccountData = ({
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  setFieldValue,
}) => {
  return (
    <div className='accForm'>
      <form onSubmit={handleSubmit}>
        <div className='formField'>
          <InputText
            placeholder="Фамилия"
            value={values[fieldNames.LAST_NAME]}
            name={fieldNames.LAST_NAME}
            error={touched[fieldNames.LAST_NAME] &&
              errors[fieldNames.LAST_NAME]
            }
            onChange={handleChange}
          />
        </div>
        <div className='formField'>
          <InputText
            placeholder="Имя"
            onChange={handleChange}
            value={values[fieldNames.FIRST_NAME]}
            name={fieldNames.FIRST_NAME}
            error={touched[fieldNames.FIRST_NAME] &&
              errors[fieldNames.FIRST_NAME]
            }
          />
        </div>
        <div className='formField'>
          <InputText
            placeholder="Отчество"
            onChange={handleChange}
            value={values[fieldNames.PATRONYMIC]}
            name={fieldNames.PATRONYMIC}
            error={touched[fieldNames.PATRONYMIC] &&
              errors[fieldNames.PATRONYMIC]
            }
          />
        </div>
        <div className='formField'>
          {/*<InputText
            placeholder="Номер телефона"
            onChange={handleChange}
            value={values[fieldNames.PHONE]}
            name={fieldNames.PHONE}
            type='phone'
            error={touched[fieldNames.PHONE] &&
              errors[fieldNames.PHONE]
            }
            disabled
          />*/}
          <Phone
            placeholder="Номер телефона"
            name={fieldNames.PHONE}
            value={values[fieldNames.PHONE]}
            setFieldValue={setFieldValue}
            error={touched[fieldNames.PHONE] &&
              errors[fieldNames.PHONE]
            }
            country={'ru'}
            disabled
          />
        </div>
        <div className='formField'>
          <InputText
            placeholder="Адрес"
            onChange={handleChange}
            value={values[fieldNames.ADDRESS]}
            name={fieldNames.ADDRESS}
            error={touched[fieldNames.ADDRESS] &&
              errors[fieldNames.ADDRESS]
            }
            disabled
          />
        </div>
        <div className='formButton'>
          <Button text="Сохранить изменения" type="submit" />
        </div>
      </form>
    </div>
  )
}

export default AccountData
