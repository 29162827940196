import { combineReducers } from 'redux'

import popupsReducer from 'components/popups/reducer'
import userReducer from 'components/user/reducer'
import ordersReducer from 'components/orders/reducer'
import citiesReducer from 'store/modules/cities/reducer'

export default combineReducers({
  popups: popupsReducer,
  user: userReducer,
  orders: ordersReducer,
  cities: citiesReducer,
})
